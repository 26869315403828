import React from "react"
import Title from "../components/Title"
import Layout from "../components/Layout"
import Moment from "react-moment"
import DetailsBlock from "../components/DetailsBlock"
import PaymentBlock from "../components/PaymentBlock"
import InvoiceItems from "../components/InvoiceItem"
import { graphql } from 'gatsby';

const InvoiceTemplate = ({ data }) => {
  const { invoice, details } = data
  console.log(invoice)
  return (
    <Layout className="border-4 p-10 rounded ">
      <div className="flex flex-row justify-between ">
        <div className="text-xs">
          <div className="text-sm font-bold pb-2">{invoice.heading} #{invoice.number}</div>
          ABN: {details.abn}
          <div>
            date: <Moment date={Date.now()} format="LL"></Moment>
          </div>
          <div>
            due:{" "}
            <Moment add={{ days: 30 }} date={Date.now()} format="LL"></Moment>
          </div>
        </div>
        <div>
          <DetailsBlock />
        </div>
      </div>
      <div>
        <InvoiceItems items={invoice.items} />
      </div>
      <PaymentBlock />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    invoice: documentsYaml(slug: { eq: $slug }) {
      heading
      number
      items {
        label
        cost
      }
    }
    details: documentsYaml(type: { eq: "details" }) {
      abn
    }
  }
`

export default InvoiceTemplate
