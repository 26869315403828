import React from 'react'

const InvoiceItem = ({children, item}) => {
  return (
    <>
      <div className="col-span-3">
      {item.label}
      </div>
      <div>
      ${item.cost}
      </div>
    </>
  )
}

const InvoiceItems = ({children, items }) => {
  return (
    <div className="grid grid-cols-4 border-2 rounded p-2 text-xs">
      <div className="uppercase col-span-3 text-xs pb-3">description</div>
      <div className="uppercase text-xs pb-3" >cost</div>
      { items.map( (item) => <InvoiceItem item={item} /> ) }
    </div>
  )
}


export default InvoiceItems
